<template>
	<section class="signup">
		<h1 class="is-size-1" v-translate>Sign up</h1>
		<b-steps v-model="activeStep" rounded :has-navigation="false" label-position="bottom" mobile-mode="compact" size="is-large" @input="focusOnFirstElement">
			<b-step-item step="1" :label="$gettext('About you')" icon="account" :clickable="true">
				<form ref="form0" @submit.prevent="checkStep">
					<b-field grouped>
						<b-field :label="$gettext('Title')">
							<b-select v-model="user.title" ref="title">
								<option>Mr.</option>
								<option>Ms.</option>
								<option>Dr.</option>
								<option>Prof.</option>
								<option>Prof. Dr.</option>
							</b-select>
						</b-field>
						<b-field :label="$gettext('Given name')" expanded>
							<b-input v-model="user.givenName" required></b-input>
						</b-field>
						<b-field :label="$gettext('Family name')" expanded>
							<b-input v-model="user.familyName" required></b-input>
						</b-field>
					</b-field>

					<b-button type="is-primary" class="is-pulled-right" tag="input" native-type="submit" :value="$gettext('Next')"></b-button>
				</form>
			</b-step-item>

			<b-step-item step="2" :label="$gettext('Your profession')" icon="medical_services" :clickable="true">
				<form ref="form1" @submit.prevent="checkStep">
					<b-field grouped>
						<b-field :label="$gettext('Email')" expanded ref="email">
							<b-input v-model="user.email" type="email" required icon="email"></b-input>
						</b-field>

						<b-field
							:label="$gettext('Mobile phone')"
							expanded
							@keyup.native.enter="checkStep"
							:message="!user.phoneNumber || isValidPhoneNumber(user.phoneNumber) ? undefined : 'Please input a valid phone number'"
							:type="{
								'is-danger': user.phoneNumber && !isValidPhoneNumber(user.phoneNumber),
							}"
						>
							<b-input v-model="user.phoneNumber" icon="cellphone" type="phone" required @blur="isValidPhoneNumber(user.phoneNumber)"></b-input>
						</b-field>
					</b-field>

					<b-field grouped>
						<b-field :label="$gettext('Your hospital')">
							<b-select placeholder="Select hospital" icon="hospital-building" :loading="isLoadingHospitals" v-model="user.hospitalId">
								<option v-for="hospital in hospitals" :value="hospital._id" :key="hospital._id">{{ hospital.name }}</option>
							</b-select>
						</b-field>

						<b-field :label="$gettext('Your role')">
							<b-select placeholder="Select role" icon="doctor" :loading="isLoadingRoles" v-model="user.roleId" required>
								<optgroup :label="roleType" v-for="roleType in roleTypes" :key="roleType">
									<option v-for="role in roles.filter(t => t.group == roleType)" :value="role._id" :key="role._id">{{ role.name }}</option>
								</optgroup>
							</b-select>
						</b-field>
					</b-field>
					<b-button type="is-primary" class="is-pulled-right" tag="input" native-type="submit" :value="$gettext('Next')"></b-button>
				</form>
			</b-step-item>

			<b-step-item step="3" :label="$gettext('Account')" icon="account-key" :clickable="true">
				<form ref="form2" @submit.prevent="validateAndCreate">
					<b-field :label="$gettext('Password')" :message="passwordErrors" :type="{ 'is-danger': passwordErrors.length > 0 }" ref="password">
						<b-input v-model="user.password" type="password" icon="key" password-reveal required @input="checkPassword" @blur="checkPassword"></b-input>
					</b-field>
					<b-button type="is-primary" class="is-pulled-right" tag="input" native-type="submit" :value="$gettext('Create account')"></b-button>
				</form>
			</b-step-item>
		</b-steps>
	</section>
</template>

<script>
import { mapActions } from 'vuex';
import { checkPasswordComplexity, isValidPhoneNumber } from '@/utils';
import { errorToast } from '@/mixins';

export default {
	data() {
		return {
			activeStep: 0,
			user: {
				title: '',
				givenName: '',
				familyName: '',
				email: '',
				phoneNumber: '',
				password: '',
				hospitalId: '',
				roleId: '',
			},
			hospitals: [],
			isLoadingHospitals: false,
			roles: [],
			isLoadingRoles: false,
			passwordErrors: [],
		};
	},
	computed: {
		roleTypes() {
			if (this.roles.length === 0) return [];
			return [...new Set(this.roles.map(t => t.group))];
		},
	},
	mixins: [errorToast],
	methods: {
		...mapActions(['getHospitals', 'getUserRoles']),
		checkPassword() {
			try {
				this.passwordErrors = [];
				return checkPasswordComplexity(this.user.password);
			} catch (err) {
				this.passwordErrors = err.message.split('|');
				return false;
			}
		},
		isValidPhoneNumber,
		checkStep() {
			if (this.$refs[`form${this.activeStep}`].checkValidity()) {
				this.activeStep++;
			}
		},
		focusOnFirstElement(step) {
			this.$refs[`form${step}`][0].focus();
		},
		validateAndCreate() {
			if (this.$refs.form0.checkValidity() && this.$refs.form1.checkValidity() && this.isValidPhoneNumber(this.user.phoneNumber) && this.checkPassword()) {
				console.log(this.user);
			} else if (!this.$refs.form1.checkValidity()) {
				this.activeStep = 0;
			} else if (!this.$refs.form1.checkValidity() || !this.isValidPhoneNumber(this.user.phoneNumber)) {
				this.activeStep = 1;
			} else if (!this.checkPassword()) {
				this.activeStep = 2;
			}
		},
		loadAsyncData() {
			this.getHospitals()
				.then(data => {
					this.hospitals = data.results;
					this.isLoadingHospitals = false;
				})
				.catch(err => {
					this.hospitals = [];
					this.total = 0;
					this.isLoadingHospitals = false;
					this.errorToast(err);
				});
			this.getUserRoles()
				.then(data => {
					this.roles = data.results;
					this.isLoadingRoles = false;
				})
				.catch(err => {
					this.roles = [];
					this.total = 0;
					this.isLoadingRoles = false;
					this.errorToast(err);
				});
		},
	},
	mounted() {
		this.loadAsyncData();
		this.$refs.form0[0].focus();
	},
};
</script>

<style lang="scss">
.signup nav.steps {
	margin: 2.5em 0;
}
</style>

import { isValidPhoneNumber } from '@/utils';

const successToast = {
	methods: {
		successToast(message) {
			this.$buefy.toast.open({
				position: 'is-bottom-right',
				message,
				duration: 5000,
				type: 'is-success',
			});
		},
	},
};

const errorToast = {
	methods: {
		errorToast(err) {
			this.$buefy.toast.open({
				position: 'is-bottom-right',
				message: `<b>${err.name}:</b> ${err.message}`,
				duration: 5000,
				type: 'is-danger',
			});
		},
	},
};

const checkPhone = {
	methods: {
		checkPhone(phone) {
			try {
				this.phoneError = '';
				if (!isValidPhoneNumber(phone)) this.phoneError = this.$gettext('This is not a valid phone number');
				return isValidPhoneNumber(phone);
			} catch (err) {
				this.phoneError = this.$gettext('This is not a valid phone number');
				return false;
			}
		},
	},
};

const translate = {
	filters: {
		translate(value, params) {
			return !value ? '' : params ? this.$gettextInterpolate(value.toString(), params) : this.$gettext(value.toString());
		},
	},
};

export { successToast, errorToast, translate, checkPhone };
